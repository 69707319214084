import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";

const PartnerDetails = () => {
  const params = useParams<{ partnerPlantCarePlanId: string }>();

  const [plantCarePlan, setPlantCarePlan] = useState<{
    plantName: string;
    plantImageUrl: string;
    plantCarePlanSections: Array<{
      description: string;
      title: string;
      type: string;
    }>;
  }>();

  useEffect(() => {
    let mounted = true;

    fetch(
      `${process.env.REACT_APP__CONTENT_API_URL}/bff/partner-web/v1/partner-plant-care-plan/${params.partnerPlantCarePlanId}/details`
    )
      .then((data) => data.json())
      .then((data) => {
        if (mounted) {
          setPlantCarePlan(data.data);
        }
      });

    return () => {
      mounted = false;
    };
  }, [params.partnerPlantCarePlanId]);

  return (
    <>
      {!!plantCarePlan && (
        <>
          <Row>
            <Col md={6} lg={4} xl={3}>
              <img
                src={plantCarePlan.plantImageUrl}
                style={{ width: "100%" }}
                alt={plantCarePlan.plantName}
              ></img>
            </Col>
          </Row>
          <div>
            <h1>{plantCarePlan.plantName}</h1>
            {plantCarePlan.plantCarePlanSections.map((section, index) => (
              <div key={index}>
                <div>
                  <h2>{section.title}</h2>
                </div>
                <div>
                  <ReactMarkdown components={{ h1: "h2" }}>
                    {section.description}
                  </ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <p>
        <Link to="..">Back</Link>
      </p>
    </>
  );
};

export default PartnerDetails;
