import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Helmet } from "react-helmet";
import { Link, Outlet } from "react-router-dom";

const PartnerWrapper = (props: { id: string }) => {
  const [partner, setPartner] =
    useState<{ name: string; logoUrl: string, isMobileIntegrationEnabled: boolean }>();
  const [list, setList] = useState([]);

  useEffect(() => {
    let mounted = true;

    fetch(
      `${process.env.REACT_APP__CONTENT_API_URL}/bff/partner-web/v1/partner-plant-care-plan/by-partner/${props.id}/details`
    )
      .then((data) => data.json())
      .then((data) => {
        if (mounted) {
          setPartner(data.data.partner);
          setList(data.data.plantCarePlans);
        }
      });

    return () => {
      mounted = false;
    };
  }, [props.id]);

  return (
    <>
      {!!partner && (
        <>
          <Helmet>
            <title>
              {partner.name} Plant Care Plans - Powered by BloomCatch
            </title>
          </Helmet>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <Link to=".">
                <img
                  alt={partner.name}
                  src={partner.logoUrl}
                  style={{ maxHeight: 200, maxWidth: "90%" }}
                ></img>
              </Link>
            </Col>
          </Row>
        </>
      )}
      <Outlet context={{ partner: partner, list: list }} />
    </>
  );
};

export default PartnerWrapper;
