import { Col, Row } from "react-grid-system";
import { Link, useOutletContext } from "react-router-dom";

const PartnerList = (props: {}) => {
  const outlet = useOutletContext<{
    partner: { isMobileIntegrationEnabled: boolean };
    list: {
      id: string;
      name: string;
      imageUrl: string;
      dynamicLinkUrl: string;
      qrCodeImageUrl: string;
    }[];
  }>();
  const { partner, list } = outlet;

  list.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <h1>Plant Care Plans</h1>
      <Row>
        {list.map((item, index) => (
          <Col
            md={6}
            lg={4}
            xl={3}
            key={index}
            style={{ paddingBottom: "20px" }}
          >
            {process.env.REACT_APP__DEVELOPMENT ||
            !partner.isMobileIntegrationEnabled ? (
              <Link to={item.id}>
                <div>
                  <img
                    src={item.imageUrl}
                    style={{ width: "100%" }}
                    alt={item.name}
                  ></img>
                </div>
                <Row align="center" wrap="nowrap">
                  <Col style={{ flex: 1 }}>
                    <h2 style={{ overflowWrap: "anywhere" }}>{item.name}</h2>
                  </Col>
                  {partner.isMobileIntegrationEnabled && (
                    <Col style={{ flex: 0 }}>
                      <img
                        src={item.qrCodeImageUrl}
                        style={{ width: "96px" }}
                        alt="QR code"
                      ></img>
                    </Col>
                  )}
                </Row>
              </Link>
            ) : (
              <a href={item.dynamicLinkUrl}>
                <div>
                  <img
                    src={item.imageUrl}
                    style={{ width: "100%" }}
                    alt={item.name}
                  ></img>
                </div>
                <Row align="center" wrap="nowrap">
                  <Col style={{ flex: 1 }}>
                    <h2 style={{ overflowWrap: "anywhere" }}>{item.name}</h2>
                  </Col>
                  <Col style={{ flex: 0 }}>
                    <img
                      src={item.qrCodeImageUrl}
                      style={{ width: "96px" }}
                      alt="QR code"
                    ></img>
                  </Col>
                </Row>
              </a>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PartnerList;
